var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"title":"Criando loja","max-width":"900","use-icon-close":"","header-color":"primary","persistent":""},on:{"close-dialog":_vm.cancel},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('mf-text-input',{staticClass:"ml-4",attrs:{"outlined":"","label":"Nome","errors-messages":_vm.errorMessages.name},model:{value:(_vm.clientUnit.name),callback:function ($$v) {_vm.$set(_vm.clientUnit, "name", $$v)},expression:"clientUnit.name"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('mf-text-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticClass:"mr-4",attrs:{"outlined":"","label":"CNPJ","errors-messages":_vm.errorMessages.cnpj},model:{value:(_vm.clientUnit.cnpj),callback:function ($$v) {_vm.$set(_vm.clientUnit, "cnpj", $$v)},expression:"clientUnit.cnpj"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{staticClass:"mr-4",attrs:{"label":"Loja teste"},model:{value:(_vm.clientUnit.is_test),callback:function ($$v) {_vm.$set(_vm.clientUnit, "is_test", $$v)},expression:"clientUnit.is_test"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('mf-action-buttons',{staticClass:"mt-2",attrs:{"primary-button":{
          text: 'Confirmar Cadastro',
          action: _vm.save,
          isVisible: true,
          isLoading: _vm.saving
        },"cancel-button":{
          text: 'Cancelar',
          action: _vm.cancel,
          isVisible: true,
          isDisabled: _vm.saving
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }