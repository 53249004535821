<template>
  <base-dialog v-model="dialog" title="Criando loja" max-width="900" use-icon-close header-color="primary" persistent @close-dialog="cancel">
    <v-row>
      <v-col cols="4">
        <mf-text-input v-model="clientUnit.name" outlined label="Nome" class="ml-4" :errors-messages="errorMessages.name" />
      </v-col>
      <v-col cols="4">
        <mf-text-input v-model="clientUnit.cnpj" v-mask="'##.###.###/####-##'" outlined label="CNPJ" class="mr-4" :errors-messages="errorMessages.cnpj" />
      </v-col>
      <v-col cols="4">
        <v-checkbox v-model="clientUnit.is_test" label="Loja teste" class="mr-4" />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col>
        <mf-action-buttons
          class="mt-2"
          :primary-button="{
            text: 'Confirmar Cadastro',
            action: save,
            isVisible: true,
            isLoading: saving
          }"
          :cancel-button="{
            text: 'Cancelar',
            action: cancel,
            isVisible: true,
            isDisabled: saving
          }"
        />
      </v-col>
    </v-row>
  </base-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { notOnlySpace, validCnpj } from '@/helpers/validators.js'
export default {
  name: 'AddUnit',
  components: {
    BaseDialog: () => import('@/components/atomic-components/atoms/BaseDialog.vue')
  },
  props: {
    value: {
      type: Boolean
    },
    saveFn: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      clientUnit: {
        name: '',
        cnpj: '',
        product: '',
        is_test: false
      },
      saving: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    errorMessages() {
      let errors = {}
      const messages = ['Campo obrigatório', 'Insira um CNPJ válido', 'Insira um nome válido']

      if (!this.$v.clientUnit.name.required) errors['name'] = messages[0]
      if (!this.$v.clientUnit.cnpj.required) errors['cnpj'] = messages[0]
      if (!this.$v.clientUnit.name.notOnlySpace) errors['name'] = messages[2]
      if (!this.$v.clientUnit.cnpj.validCnpj) errors['cnpj'] = messages[1]

      return errors
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.clientUnit = {
          name: '',
          cnpj: '',
          is_test: false
        }
      }
    }
  },
  methods: {
    async save() {
      this.$v.$touch()
      if (this.$v.$error) {
        this.saving = false
        return this.$alert({
          alert_message: 'Preencha todos os campos corretamente.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }

      this.saving = true
      try {
        await this.saveFn(this.clientUnit)
        this.dialog = false
      } catch (err) {
        this.$alert({
          alert_message: 'Erro ao salvar loja.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.saving = false
      }
    },
    cancel() {
      this.dialog = false
    }
  },
  validations: {
    clientUnit: {
      name: { required, notOnlySpace },
      cnpj: { required, validCnpj }
    }
  }
}
</script>
